import { defineStore } from 'pinia'

export interface SettingStoreState {
  isMenu: boolean
  isLoading: boolean
}

export const useSettingStore = defineStore('setting', {
  state: (): SettingStoreState => ({
    isMenu: false,
    isLoading: false,
  }),
  actions: {
    // メニューを開閉する
    toggleMenu(): void {
      this.isMenu = !this.isMenu
    },
    // ローディングを開始する
    startLoading(): void {
      this.isLoading = true
    },
    // ローディングを終了する
    stopLoading(): void {
      this.isLoading = false
    },
  },
})
